.table-container {
    width: 100%;
    overflow-x: auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.custom-table thead tr {
    border-bottom: 2px solid #3976e2;
}

.custom-table th {
    font-weight: 600;
    padding: 10px 15px;
    text-align: left;
}

.custom-table th.column-right {
    text-align: right;
}

.custom-table th.column-center {
    text-align: center;
}

.custom-table td {
    font-weight: 500;
    padding: 20px 15px;
}

.custom-table td.column-right {
    text-align: right;
}

.custom-table td.column-center {
    text-align: center;
}

.custom-table tbody tr {
    border-bottom: 2px solid #eef3ff;
}

.custom-table tbody tr:hover {
    background-color: #ededed;
}

.custom-table .column-name {
    width: 30%;
    text-align: left;
}

.custom-table .column-right,
.custom-table .column-center {
    white-space: nowrap;
}