.filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    padding: 5px 10px;
}

.filter-container:hover {
    cursor: pointer;
    background-color: #ededed;
}

.filter-container.active {
    background-color: #ededed;
}

.filter-container img {
    width: 14px;
    height: 8px;
}