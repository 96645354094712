.companies-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.companies-header h2 {
    font-size: 28px;
    font-weight: 700;
}

.statistics-card-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 15px;
}