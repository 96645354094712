.company-table-filter-container {
  display: flex;
  justify-content: space-between;
  margin-top: 160px;
  margin-bottom: 15px;
}

.company-table-filter-container label {
  font-size: 18px;
  font-weight: 600;
  color: #c4c4c4;
}

.container-different-filters {
  display: flex;
  gap: 50px;
}

.change-filter-container {
  margin-top: 100px;
  display: flex;
  color: #252525;
  font-size: 20px;
  font-weight: 600;
  gap: 10px;
}

.change-filter-container div {
  padding: 5px 15px;
  border-radius: 25px;
  margin: 30px 0px;
  cursor: pointer;
}

.change-filter-container div.active {
  background-color: rgb(57, 118, 226, 0.6);
}
