.green-action-button {
  width: 100%;
  border: none;
  color: #fafafa;
  background-color: #1cd93c;
  font-weight: 600;
  font-size: 26px;
  height: 50px;
  border-radius: 12px;
}

.green-action-button:hover {
  border: #1447d7 2px solid;
}
