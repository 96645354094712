.blue-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0px;
}

.blue-input-label {
  font-size: 18px;
  font-weight: 600;
  color: var(--color);
  margin-bottom: 5px;
}

.blue-input {
  background-color: #eef3ff;
  border: none;
  border-radius: 12px;
  height: 40px;
  padding: 0px 10px;
  width: 97%;
}
