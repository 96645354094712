.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fafafa;
  width: 5%;
  padding: 20px 0px;
  height: calc(100% - 40px);
  gap: 20px 0px;
}

.sidebar-container img {
  padding: 10px;
  border-radius: 10px;
}

.sidebar-container img:hover {
  background-color: #3976e24d;
}

.active {
  background-color: #3976e24d;
}
