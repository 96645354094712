.date-range-picker-container {
    position: absolute;
    margin: 10px 0px;
}

.companies-header-date {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.calendar-icon {
    padding: 5px;
    border-radius: 5px;
}

.calendar-icon:hover {
    background-color: #3976e24d;
}