.search-bar-filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    padding: 5px 10px;
    background-color: #ededed;
    border: 3px solid #ededed;
}

.search-bar-filter-container:hover {
    cursor: pointer;
    border: 3px solid #3976e24d;
}

.search-bar-filter-container input {
    border: none;
    background-color: transparent;
}