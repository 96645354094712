.statistics-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background-color: #eef3ff;
  border-radius: 10px;
  padding: 20px 30px;
  width: 45%;
}

.statistics-card-content {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.statistics-card-title label {
  font-size: 18px;
  font-weight: 600;
}

.statistics-card-title p {
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.statistics-card-percentage {
  font-size: 30px;
  font-weight: 700;
  color: #1cd93c;
}
