.footer {
  background-color: #252525;
  color: #fafafa;
  padding: 15px 0px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
