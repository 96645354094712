.login-container {
  display: flex;
  height: calc(100% - 143px);
  width: 100%;
  position: absolute;
}

.login-form-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-and-slogan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -60px;
}

.login-log-container {
  width: 75%;
}

.login-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.login-image-size {
  height: calc(100% - 250px);
  width: calc(100% - 150px);
  max-width: 850px;
}

.reset-password-link-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
}

.reset-password-link-label {
  font-size: 12px;
  font-weight: 600;
  color: var(--color);
  text-decoration: underline;
  font-style: italic;
}
