.navbar-container {
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 30px;
}

.language-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px 10px;
}

.notifcation-icon-container-and-profile-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px 30px;
}

.profile-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.profile-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d9d9d9;
}
