body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

:root {
  --color: #252525;
}

[data-theme="dark"] {
  --color: #fafafa;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input:focus {
  outline: none;
}