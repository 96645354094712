.main-container {
  display: flex;
  height: calc(100% - 143px);
}

.main-margin {
  width: 95%;
  height: calc(100% - 60px);
  padding: 30px 50px;
  overflow-y: auto;
  overflow-x: hidden;
}